$(document).ready(function() {
    $('.login-form').hide();
    $('.login-form2').hide();
    
});

$(document.body).on('click', '.profile-btn, #forgotLink', function () {
    $('.login-form').slideToggle()
});

$(document.body).on('click', '.profile-btn2', function() {
    $('.srch-sec').hide();
    $('.login-form2').slideToggle();
    // $('.login-form2').not($(this).parent().find('.login-form2')).slideUp(400);
});

    // $("body").click(function (e) {

    //     var $sender = $(e.target);
    //     if ($sender.closest(".login-form").length) {
    //         return ;
    //     } 
    //     $(".login-form").slideUp();
    //     // $(".login-form2").slideUp();
            
    // });

    




$(document.body).on('click', '.prd-clck', function () {
    if($(this).hasClass('showSubMenu')){
        $(this).removeClass('showSubMenu');
        //$('.subnav-main').hide();
        $('.subnav-main').slideUp('fast');
    } else {
        //$('.subnav-main').show();
        //$('.sidenav-inner').hide();
        $('.subnav-main').slideDown('fast');
        $(this).addClass('showSubMenu');
        $('body').removeAttr('style');
    }
    
});


$(document.body).on('click', '.bck', function () {
    $('.subnav-main').hide();
    $('.sidenav-inner').show();
});





$(document.body).on('click', '.login-form2 ul li a', function() {
    $('.login-form2').slideToggle();
})


$(document.body).on('click', '.show-more', function(){
    const parent = $(this).parent();
    parent.find('ul').children('li:gt(5)').toggleClass('d-none')

    if($(this).text() == '+ SEE MORE') {
        $(this).text('+ SEE LESS')
    } else {
        $(this).text('+ SEE MORE')
    }
}) 

$(".diffet-addrs").hide();
