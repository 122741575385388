/* Open the sidenav */
function openNav() {
  //document.getElementById("mySidenav").style.width = "100%";
  $("#mySidenav").animate({ width: '395px'}, 500);
  $("body").css({"overflow":"auto","padding-right":"0px"});
}

/* Close/hide the sidenav */
function closeNav() {
  //document.getElementById("mySidenav").style.width = "0";
  $("#mySidenav").animate({ width: 0}, 500);
  $("body").css({"overflow":"auto", "padding-right":"0px"});
   $('.prd-clck').removeClass('showSubMenu');
   $('.subnav-main').slideUp('fast');
   //$('.subnav-main').hide();
   $('.sidenav-inner').show();
}

$(document).ready(function () {

      $('.srch-cros a').click(function(){
        $('.srch-sec').hide();
        return false;
      });

      $('.profile-btn').click(function () {
        $('.login-form').slideToggle();
        $('.srch-sec').hide();
        return false;
      });

      
      // login register popup disable on body click
      $(document).mouseup(function (e){

        // Login Register Button Close
        var loginDfrm = $(".login-form");
        var loginDbtn = $(".register-btn");
        if (!loginDfrm.is(e.target) && !loginDbtn.is(e.target) && loginDfrm.has(e.target).length === 0){
            loginDfrm.slideUp();
        }

        // My Account Dropdown Close
        var myaccFrmWrap = $(".login-form2");
        var myaccBtnWrap = $(".profile-btn2");
        if (!myaccFrmWrap.is(e.target) && !myaccBtnWrap.is(e.target) && myaccFrmWrap.has(e.target).length === 0){
            myaccFrmWrap.slideUp();
        }

        // Search Button Settings
        var searchMainWrap = $(".srch-sec");
        var searchBtnWrap = $(".srch-cros a");
        if (!searchMainWrap.is(e.target) && !searchBtnWrap.is(e.target) && searchMainWrap.has(e.target).length === 0){
            searchMainWrap.hide();
        }

        // Main Nav Settings
        var navMainWrap = $(".navSec");
        var navBtnWrap = $(".bars");
        if (!navMainWrap.is(e.target) && !navBtnWrap.is(e.target) && navMainWrap.has(e.target).length === 0){
            //navMainWrap.hide();
            closeNav();
        }

        
      });
      
      

      $(".back-tp").click(function() {
          $("html, body").animate({scrollTop: 0}, 1000);
      });

    

      $('.search-btn').click(function(){
        $('.srch-sec').show();
        $('.login-form').slideUp();
        $('.login-form2').slideUp();
      });

     $(document).on('click', '.side-section > h3', function() {
        $('.schld-show').slideToggle();
        $(this).find('span').toggleClass('scdul-icon2');

        $(this).parent().find('ul').slideToggle();

     });

     



 });
$(window).scroll(function() {
    if ($(this).scrollTop() > 450 ) {
        $('.back-tp').fadeIn();
    } else {
        $('.back-tp').fadeOut();
    }
});

